import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Button, FacebookBtn, GoogleBtn } from 'components/button'
import { HelperText, MetaData } from 'components/display'
import { EmailInput, PasswordInput, Radio } from 'components/input'
import { Link } from 'components/link'
import { Display, Text } from 'components/typo'
import useGTM from 'hooks/useGTM'
import { format } from 'utils'

import LoginImage from './assets/login.png'

const MainPage = (props) => {
  const theme = useTheme()
  const history = useHistory()
  const [loading, setLoading] = useState({})
  const [error, setError] = useState({})
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSavePass, setIsSavePass] = useState(true)
  const [isSubmit, setSubmit] = useState(false)
  const { onPageLoad, onEvent } = useGTM()

  const onInit = useCallback(async () => {
    try {
      const res = await props.member.getLocalPassword()
      if (res) {
        setEmail(res.email || '')
        setPassword(res.password || '')
      }
    } catch (e) {
      console.log('e', e.message)
    }
  }, [props.member])

  useEffect(() => {
    onInit()
  }, [onInit])

  useEffect(() => {
    onPageLoad({ pageName: 'login' })
  }, [onPageLoad])

  const onEmail = async (e) => {
    e.preventDefault()
    try {
      setError({})
      setLoading({ email: true })
      setSubmit(true)

      await props.member.loginByEmail({
        email,
        password,
        isNotSave: !isSavePass,
      })

      const { pathname } = props.location

      if (pathname.includes('account/confirm') === false) {
        history.push('/account/profile')
      }
    } catch (e) {
      let errorMsg = e.message
      if (
        [
          'There is no user record corresponding to this identifier.' +
            ' The user may have been deleted.',
          'EMAIL_NOT_FOUND',
        ].includes(e.message)
      ) {
        errorMsg = 'ไม่พบอีเมลของคุณอยู่ในระบบ'
      }

      if (e.code === 'auth/wrong-password') {
        errorMsg = 'อีเมลหรือรหัสผ่านของคุณไม่ถูกต้อง'
      }

      setError({ email: errorMsg })
    }
    setLoading({})
  }

  const onGoogle = async () => {
    onEvent({
      pageName: 'login',
      eventName: 'login-with-gmail',
    })
    try {
      setError({})
      setLoading({ google: true })
      await props.member.loginByGoogle()
      history.push('/account/profile')
    } catch (e) {
      const message = format.getError(e.message)
      if (message) {
        setError({ google: e.message })
      }
    }
    setLoading({})
  }

  const onFacebook = async () => {
    onEvent({
      pageName: 'login',
      eventName: 'login-with-facebook',
    })
    try {
      setError({})
      setLoading({ facebook: true })
      await props.member.loginByFacebook()
      history.push('/account/profile')
    } catch (e) {
      setError({ facebook: e.message })
    }
    setLoading({})
  }

  const onEmailChange = (val) => {
    setError({})
    const result = props.member.validateEmail(val)
    if (result.error) {
      setError({ email: 'รูปแบบอีเมลไม่ถูกต้อง' })
    }

    setEmail(result.value)
  }

  const onPasswordChange = (val) => {
    setError({ email: false })
    setPassword(val)
  }

  const onClear = () => {
    setSubmit(false)
    setError({})
  }

  const onSubmit = () => {
    onEvent({
      pageName: 'login',
      eventName: 'login-login-button-click',
    })
  }

  const onRegister = () => {
    onEvent({
      pageName: 'login',
      eventName: 'login-register-link-click',
    })
  }

  const onForgetPassword = () => {
    onEvent({
      pageName: 'login',
      eventName: 'login-forget-password-link-click',
    })
  }

  const onTermAndPolicyClick = () => {
    onEvent({
      pageName: 'login',
      eventName: 'login-term-and-policy-click',
    })
  }

  const isLoading = Object.keys(loading).length > 0
  const isEmailError = isSubmit && error.email
  const isDisabled = isLoading || isEmailError || !email || !password

  return (
    <PageView>
      <MetaData title="เข้าสู่ระบบ" description="เข้าสู่ระบบ" />
      <ImageContainer>
        <LoginImageWrapper
          src={LoginImage}
          alt="the woman is thinking with Friday mascot."
        />
      </ImageContainer>

      <Login>
        <LoginForm onSubmit={onEmail} noValidate>
          <HeaderBox>
            <Display bold mobileSize="small">
              เข้าสู่ระบบ
            </Display>
          </HeaderBox>

          <EmailInput
            autoFocus
            value={email}
            errorText={isEmailError && 'อีเมลไม่ถูกต้อง'}
            onClear={onClear}
            onChange={onEmailChange}
          />
          <PasswordInput
            value={password}
            errorText={isEmailError && 'รหัสผ่านไม่ถูกต้อง'}
            onClear={onClear}
            onChange={onPasswordChange}
          />
          <CheckboxWrapper>
            <Radio
              checked={isSavePass}
              onCheck={setIsSavePass}
              label="จดจำฉันไว้ในระบบ"
            />
          </CheckboxWrapper>

          <Button
            large
            type="submit"
            onClick={onSubmit}
            disabled={isDisabled}
            loading={loading.email}
            color="primary"
            text="เข้าสู่ระบบ"
          />
        </LoginForm>
        <HelperBox>
          <Link to="/register" onClick={onRegister}>
            <Text size="small" bold primary>
              สร้างบัญชี
            </Text>
          </Link>
          <Text size="small" color={theme.gray.line}>
            |
          </Text>
          <Link to="/forgot-password" onClick={onForgetPassword}>
            <Text size="small" bold primary>
              ลืมรหัสผ่าน
            </Text>
          </Link>
        </HelperBox>

        <Separator>
          <TextMark>
            <Text size="small" gray>
              หรือ
            </Text>
          </TextMark>
        </Separator>

        <LoginOption>
          <GoogleBtn
            loading={loading.google}
            disabled={isLoading}
            text={<b>เข้าสู่ระบบผ่าน Gmail</b>}
            onClick={onGoogle}
          />
          <FacebookBtn
            loading={loading.facebook}
            disabled={isLoading}
            text={<b>เข้าสู่ระบบผ่าน Facebook</b>}
            onClick={onFacebook}
          />
          <HelperText errorText={error.facebook || error.google} />
        </LoginOption>

        <InfoText>
          <Text size="small" gray>
            การสร้างบัญชีผู้ใช้หมายความว่าคุณได้อ่านและยอมรับ&nbsp;
            <CustomLink to="/term" onClick={onTermAndPolicyClick}>
              <Text size="small" bold primary>
                เงื่อนไขการใช้บริการ
              </Text>
            </CustomLink>
            &nbsp;และ&nbsp;
            <CustomLink to="/policy" onClick={onTermAndPolicyClick}>
              <Text size="small" bold primary>
                นโยบายความเป็นส่วนตัว
              </Text>
            </CustomLink>
            &nbsp;ของเรา
          </Text>
        </InfoText>
      </Login>
    </PageView>
  )
}

const PageView = styled.div`
  display: grid;
  grid-template-columns: 407px 455px;

  width: 100%;
  max-width: 862px;
  height: 100%;
  margin: 60px auto 0;
  border: 1px solid #d9dbe9;
  border-radius: 24px;
  overflow: hidden;

  @media screen and (max-width: 990px) {
    justify-items: center;
    margin: 40px auto 0;
    grid-template-columns: 1fr;
    border: none;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  background: linear-gradient(170.55deg, #9c7aff -5.34%, #2a00a2 85.23%);

  @media screen and (max-width: 990px) {
    display: none;
  }
`

const LoginImageWrapper = styled.img`
  width: 100%;
  object-fit: cover;
`

const Separator = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    border-top: 1px solid ${(props) => props.theme.gray.line};
    width: 100%;
    transform: translateY(-50%);
  }
`

const TextMark = styled.mark`
  background-color: white;
  padding: 0 20px;
`

const Login = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;

  width: 100%;
  max-width: 455px;
  padding: 40px;

  @media screen and (max-width: 990px) {
    padding: 0 16px;
  }
`

const HeaderBox = styled.div`
  margin-bottom: 26px;

  @media screen and (max-width: 990px) {
    margin-bottom: 40px;
  }
`

const LoginForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;

  row-gap: 24px;
  width: 100%;

  > div.input-container {
    > div {
      gap: 16px;
    }
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
`

const HelperBox = styled.div`
  display: flex;
  column-gap: 8px;
`

const LoginOption = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: 24px;

  color: ${(props) => props.theme.gray.body} !important;
`

const InfoText = styled.div`
  column-gap: 10px;
`

const CustomLink = styled(Link)`
  margin: 0 8px;
`

export default inject('member')(observer(MainPage))
